function updateCDNSettings(cdnSettings, nonce, pciCompliance, pciCompliantGtmContainerId) {
    if (pciCompliance && cdnSettings.integrations) {
        const gtmConfiguration = cdnSettings.integrations['Google Tag Manager'];
        if (gtmConfiguration) {
            gtmConfiguration.nonce = nonce;
            if (pciCompliantGtmContainerId) {
                gtmConfiguration.containerId = pciCompliantGtmContainerId;
            }
        }

        cdnSettings.integrations = filterNonPciCompliantIntegrations(cdnSettings);
        cdnSettings.remotePlugins = filterNonPciCompliantRemotePlugins(cdnSettings.remotePlugins);
    }

    return cdnSettings;
}

function filterNonPciCompliantRemotePlugins(remotePlugins) {
    return (
        remotePlugins?.filter((plugin) => plugin.name === 'Google Tag Manager' || plugin.name === 'Segment.io') ?? []
    );
}

function filterNonPciCompliantIntegrations(cdnSettings) {
    const integrations = {};

    for (const key in cdnSettings.integrations) {
        if (key === 'Google Tag Manager' || key === 'Segment.io') {
            integrations[key] = cdnSettings.integrations[key];
        }
    }

    return integrations;
}

module.exports = { updateCDNSettings };
