function getPciCompliantGtmContainerOverride(tenant) {
    const normalizedTenant = tenant.toLowerCase();
    if (normalizedTenant === 'vistaprint') {
        return 'GTM-MQMB4G9R';
    }

    return undefined;
}

module.exports = {
    getPciCompliantGtmContainerOverride
};
